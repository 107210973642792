<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="itemTable"
        title="체크리스트 항목별 개선목록"
        tableId="checklistGrid"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="itemTable.columns"
        :data="itemTable.data"
        :merge="itemTable.merge"
        :gridHeight="gridheight"
      >
        <template v-slot:customArea="{ props }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              outline square
              :color="item.ibmClassCd === 'IC00000001' ? 'light-blue' : 'red-6'"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <template v-else>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="항목외 개선 목록"
        :columns="gridImpr.columns"
        :editable="disabled"
        :data="result.equipmentImproveModels"
        :merge="gridImpr.merge"
        selection="multiple"
        rowKey="equipImproveId"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="disabled && result.equipmentImproveModels.length > 0" label="LBLREMOVE" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            <c-btn
              v-if="disabled && (result.noPlanResultEnrollFlag !== 'Y' && isMultiple) && result.equipmentImproveModels.length > 0"
              :url="insertUrl"
              :isSubmit="isSave"
              :param="result.equipmentImproveModels"
              mappingType="POST"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveImprove"
              @btnCallback="saveCallback" 
            />
          </q-btn-group>
        </template>
        <template v-if="isMultiple" slot="table-chip">
          <q-btn-group outline >
            <q-chip outline square>
                <q-avatar icon="push_pin" color="blue-6" text-color="white" /> 
                {{ "설비명(관리번호) : " + result.equipmentName + '(' + result.equipmentCd + ')'}}
            </q-chip>
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
            <q-chip
              v-for="(item, index) in $comm.ibmTagItems(props.row, colorItems)"
              :key="index"
              outline square
              :color="item.ibmClassCd === 'IC00000001' ? 'blue-6' : 'orange'"
              :clickable="true"
              text-color="white"
              style="margin-bottom:4px !important;"
              @click="linkClick(item, props.row)">
              {{item.title}}
              <c-assess-tooltip 
                :ibmTooltip="item.ibmTooltip"
                :ibmClassCd="item.ibmClassCd"
              />
            </q-chip>
          </template>
          <div v-if="props.row.editFlag!=='C'">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                v-if="disabled"
                icon="add"
                color="blue-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props, '1')">
                <q-tooltip>
                  개선요청등록
                </q-tooltip>
              </q-btn>
                <q-btn
                  v-if="disabled"
                  icon="add"
                  color="orange"
                  text-color="white"
                  class="ColumInnerBtn"
                  align="center"
                  @click.prevent="innerBtnClicked(col, props, '2')">
                  <q-tooltip>
                    즉시조치등록
                  </q-tooltip>
                </q-btn>
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-cause-prevention',
  props: {
    result: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentName: '',
        checkStatusCd: '',
        minEquipmentCheckId: '',
        equipmentResultList: [],
        noPlanResultEnrollFlag: 'N',
      }),
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      equipInfo: {
        plantName: '',
        machineryClassificationName: '',
        itemNo: '',
        machineryName: '',
        emplacementMonth: '',
        inspectionSubjectFlagName: '',
        inspectionCycleName: '',
        gradeName: '',
        resultName: '',
        departmentDeptName: '',
        departmentUserName: '',
        inspectionKindName: '',
      },
      itemGrid: {
        merge: [
          { index: 0, colName: 'checklistName' },
        ],
        columns: [
          {
            name: 'checklistName',
            field: 'checklistName',
            label: '체크리스트 항목',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: "selectValueName",
            field: "selectValueName",
            label: "양호/불량/해당없음",
            style: 'width:130px',
            align: "center",
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'center',
            style: 'width: 250px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'ibmClassName',
            field: 'ibmClassName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'actionContents',
            field: 'actionContents',
            label: '조치내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            label: '조치완료일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      items: [],
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
      colorItems: [],
      component: null,
    };
    
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.editable && this.result.checkStatusCd!=='MCSC000015'
    },
  },
  watch: {
    'research.item'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.min.equipment.improve.get.url;
      this.insertUrl = transactionConfig.sop.min.equipment.improve.insert.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.improve.delete.url;
    },
    getDetail() {
      if (this.result.minEquipmentCheckId) {
        this.$http.url = this.$format(this.getUrl, this.result.minEquipmentCheckId, this.result.equipmentCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.itemGrid.data = this.$_.clone(_result.data);
        },);
      }
    },
    getImpList() {
      if (this.popupParam.sopCtoId) {
        this.$http.url = this.impListUrl;
        this.$http.param = {
          ibmTaskTypeCd: 'ITT0000060', 
          ibmTaskUnderTypeCd: 'ITTU000090', 
          relationTableKey: this.popupParam.sopCtoId
        };
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridImpr.data = _result.data;
        },);
      }
    },
    linkClick(data, row, num) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = 'LBLIMPROVE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        if (num == '2') {
          this.popupOptions.closeCallback = this.closeImprPopup2;
        } else {
          this.popupOptions.closeCallback = this.closeImprPopup;
        }
      }
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    linkClick2(row) {
      this.linkClick(row, row, '2');
    },
    closeImprPopup2() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    },
    addImpr() {
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: this.ctoData.sopName + ' / ' + this.ctoData.processName,
        relationTableKey: this.popupParam.sopCtoId,
        ibmTaskTypeCd: 'ITT0000060',
        ibmTaskUnderTypeCd: 'ITTU000090', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    addiimImpr() {
      this.popupOptions.title = 'LBLIMMEDIATE';
      this.popupOptions.param = {
        requestContents: this.ctoData.sopName + ' / ' + this.ctoData.processName,
        relationTableKey: this.popupParam.sopCtoId,
        ibmTaskTypeCd: 'ITT0000060',
        ibmTaskUnderTypeCd: 'ITTU000090', 
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getImpList();
    }
  }
};
</script>